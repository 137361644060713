import { useEffect, useState } from "react";
import { Button, Container, Form, Table, Modal, FormControl } from "react-bootstrap";
import { getAttendanceList, getSessionList, setAttendance } from "../../firebase";

const initialAttendees = [
    { id: 1, name: "이충현", attendance: ["출석", "출석", "출석", "출석"] },
    { id: 2, name: "이찬", attendance: ["출석", "출석", "출석", "출석"] },
    // ... 초기 데이터
];

function AttendanceView() {
    const [attendees, setAttendees] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showReflect, setShowReflect] = useState(false);
    const [showDelete, setShowDelete] = useState(null);
    const [newAttendee, setNewAttendee] = useState({ memberId: "", memberName: "" });
    const [numSessions, setNumSessions] = useState(1);
    const [sessionName, setSessionName] = useState([]);

    useEffect(() => console.log(showDelete), [showDelete]);

    useEffect(() => {
        if (attendees.length === 0) {
            getAttendanceList()
                .then(res => setAttendees(res));
        }
        if (sessionName.length === 0) {
            getSessionList()
                .then(res => {
                    setSessionName(res);
                    setNumSessions(res.length);
                });
        }
    }, []);

    const updateSessionName = (index, name) => {
        if (sessionName[index] === undefined) {
            setSessionName([...sessionName, name]);
        } else {
            setSessionName(sessionName.map((n, i) => i === index ? new Date(name).getTime() : n));
        }
    }

    const addAttendee = () => {
        setAttendees([...attendees, { ...newAttendee, memberStatus: Array(numSessions).fill("absent") }]);
        setShowModal(false);
    };

    const deleteAttendee = (id) => {
        setAttendees(attendees.filter(attendee => attendee.memberId !== id));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewAttendee(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const updateAttendance = (id, sessionIndex, status) => {
        setAttendees(attendees.map(attendee => {
            if (attendee.memberId === id) {
                const updatedAttendance = [...attendee.memberStatus];
                updatedAttendance[sessionIndex] = status;
                return { ...attendee, memberStatus: updatedAttendance };
            }
            return attendee;
        }));
    };

    const updateFirebaseSessions = async (newNumSessions) => {
        // Update the session names and attendee attendance in Firebase
        try {
            const newSessionName = sessionName.slice(0, newNumSessions);
            await setAttendance(newSessionName, attendees);
            setSessionName(newSessionName);
        } catch (error) {
            console.error("Error updating Firebase sessions:", error);
        }
    };

    const handleSessionChange = (e) => {
        const newNumSessions = parseInt(e.target.value) || 1;
        setNumSessions(newNumSessions);
        setAttendees(attendees.map(attendee => {
            const updatedAttendance = [...attendee.memberStatus];
            if (newNumSessions > updatedAttendance.length) {
                while (updatedAttendance.length < newNumSessions) {
                    updatedAttendance.push("absent");
                }
            } else if (newNumSessions < updatedAttendance.length) {
                updatedAttendance.length = newNumSessions;
            }
            return { ...attendee, memberStatus: updatedAttendance };
        }));
        if (newNumSessions > sessionName.length) {
            const createSession = [];
            for (let i = sessionName.length; i < newNumSessions; i++) {
                createSession.push(new Date().getTime());
            }
            setSessionName([...sessionName, ...createSession]);
        } else {
            updateFirebaseSessions(newNumSessions);
        }
    };

    const renderHeader = () => {
        const headers = [
            <th key="number" style={{ width: "100px" }}>학번</th>,
            <th key="name" style={{ width: "500px" }}>이름</th>
        ];
        for (let i = 0; i < numSessions; i++) {
            if (sessionName[i] === undefined) continue;
            const datetime = new Date(sessionName[i]);
            const year = datetime.getFullYear();
            let month = '' + (datetime.getMonth() + 1);
            if (month.length === 1) month = "0" + month;
            let date = '' + (datetime.getDate());
            if (date.length === 1) date = "0" + date;
            const dateline = year + "-" + month + "-" + date;
            headers.push(<th key={`session-${i}`} style={{ width: "180px" }}><FormControl type="date" value={dateline} onChange={(e) => updateSessionName(i, e.target.value)} /></th>);
        }
        headers.push(<th key="actions" style={{ width: "100px" }}>Actions</th>);
        return <tr>{headers}</tr>;
    };

    const renderBody = () => {
        return attendees.map((attendee, index) => (
            <tr key={attendee.id}>
                <td>{attendee.memberId}</td>
                <td>{attendee.memberName}</td>
                {attendee.memberStatus.map((status, sessionIndex) => {
                    if (sessionIndex < numSessions) {
                        return <td key={sessionIndex}>
                            <Form.Select
                                value={status}
                                onChange={(e) => updateAttendance(attendee.memberId, sessionIndex, e.target.value)}
                            >
                                <option value="present">출석</option>
                                <option value="absent">불참</option>
                            </Form.Select>
                        </td>
                    }
                    return null;
                })}
                <td>
                    <Button variant="danger" onClick={() => setShowDelete(attendee.memberId)}>
                        삭제
                    </Button>
                </td>
            </tr>
        ));
    };

    return (
        <Container className="mt-5">
            <h2>출석 관리</h2>
            <div className="d-flex justify-content-between align-items-center">
                <Form.Group className="mb-8" controlId="numSessions">
                    <Form.Label>세션 수</Form.Label>
                    <Form.Control
                        type="number"
                        min="1"
                        value={numSessions}
                        onChange={handleSessionChange}
                    />
                </Form.Group>

                <Button variant="primary" onClick={() => setShowModal(true)}>
                    출석인원 추가
                </Button>
            </div>

            <div style={{ overflowX: "auto" }} className="w-100">
                <Table striped bordered hover variant="dark" className="mt-3" style={{ width: (210 + numSessions * 180) + "px" }}>
                    <thead>
                        {renderHeader()}
                    </thead>
                    <tbody>
                        {renderBody()}
                    </tbody>
                </Table>
            </div>
            <Button variant="primary" onClick={() => setShowReflect(true)}>
                출석 반영
            </Button>
            <Modal show={showReflect} onHide={() => setShowReflect(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>출석 반영</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>출석 반영을 하시겠습니까?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => {
                        setAttendance(sessionName, attendees);
                        setShowReflect(false);
                    }}>반영</Button>
                    <Button variant="secondary" onClick={() => setShowReflect(false)}>취소</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showDelete != null} onHide={() => setShowDelete(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>출석인원 삭제</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>출석인원을 삭제하시겠습니까?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => {
                        deleteAttendee(showDelete);
                        setShowDelete(null);
                    }}>삭제</Button>
                    <Button variant="secondary" onClick={() => setShowDelete(null)}>취소</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>새로운 출석인원 추가</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>학번</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="학번을 입력해주세요"
                                name="memberId"
                                value={newAttendee.memberId}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>이름</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="이름을 입력해주세요"
                                name="memberName"
                                value={newAttendee.memberName}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        닫기
                    </Button>
                    <Button variant="primary" onClick={addAttendee}>
                        추가
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default AttendanceView;
