import { useState } from "react";
import { Button, Container, Form, Table, Modal } from "react-bootstrap";
import { SHA256, login } from "../../firebase";
import { useNavigate } from "react-router-dom";

function LoginForm(props) {

    const navigation = useNavigate();
    const [id, setId] = useState("");
    const [pw, setPw] = useState("");
    const [showModal, setShowModal] = useState(false);

    function loginProc() {
        if(id === "" || pw === ""){
            alert("아이디 또는 비밀번호가 일치하지 않습니다.");
            return;
        }
        login(id, pw)
        .then(res => {
            if(res !== null){
                window.sessionStorage.setItem("token", JSON.stringify(res));
                window.sessionStorage.setItem("validation", SHA256(JSON.stringify(res)));
                props.onLogin(res);
                navigation("/");
            } else {
                alert("아이디 또는 비밀번호가 일치하지 않습니다.");
            }
        });
    }

    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);

    return <>
        <Container className="d-flex flex-column w-75 justify-content-center mt-5">
            <h3 className="w-100 text-start mb-3">로그인</h3>
            <Container className="d-flex flex-column w-100 justify-content-center border-top border-secondary ">
                <Container className="w-100 d-flex justify-content-center mt-5">
                    <Table className="table-borderless mt-3 w-50 text-start">
                        <tbody>
                            <tr>
                                <td className="align-middle bg-transparent text-white">
                                    아이디 :
                                </td>
                                <td className="align-middle bg-transparent">
                                    <Form.Control type="text" className="bg-transparent text-white" value={id} onChange={(e) => setId(e.target.value)} />
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle bg-transparent text-white">
                                    비밀번호 :
                                </td>
                                <td className="align-middle bg-transparent">
                                    <Form.Control type="password" className="bg-transparent text-white" value={pw} onChange={(e) => setPw(e.target.value)} onKeyDown={(e)=>{
                                        if(e.code === "Enter"){
                                            loginProc();
                                        }
                                    }}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="align-middle bg-transparent">
                                    
                                </td>
                                <span onClick={handleShowModal} style={{cursor: 'pointer', textDecoration: 'underline', backgroundColor: "#282c34"}}>
                                    비밀번호를 잊어버리셨나요?
                                </span>
                            </tr>
                            <tr>
                                <td colSpan={2} className="align-middle bg-transparent">
                                    <Button className="w-100" onClick={loginProc}>로그인</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Container>
            </Container>
        </Container>

        <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title>비밀번호 찾기</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <span>어쩌라구요</span>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseModal}>
                    닫기
                </Button>
                <Button variant="primary" onClick={() => { alert("회장에게 말해주세요"); handleCloseModal(); }}>
                    확인
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}

export default LoginForm;