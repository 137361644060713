import React, { useState } from "react";
import { Button, Container, Form, Table, Modal, FormControl } from "react-bootstrap";
import { getFirestore, collection, getDocs } from "firebase/firestore";

// Firebase Firestore 초기화
const db = getFirestore();

export const getMembers = async () => {
    try {
        const querySnapshot = await getDocs(collection(db, "Member"));
        const members = [];
        querySnapshot.forEach((doc) => {
            members.push({ id: doc.id, ...doc.data() });
        });
        return members;
    } catch (error) {
        console.error("Error fetching members: ", error);
        return [];
    }
};

function RandomPickerView() {
    const [users, setUsers] = useState([
    ]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [newUser, setNewUser] = useState({ id: "", name: "" });
    const [randomUser, setRandomUser] = useState(null);

    const addUser = () => {
        if (newUser.id && newUser.name && !users.some(user => user.id === newUser.id)) {
            setUsers([...users, newUser]);
            setNewUser({ id: "", name: "" });
            setShowModal(false);
        } else {
            alert("유효한 학번과 이름을 입력하세요. 학번은 중복될 수 없습니다.");
        }
    };

    const pickRandomUser = () => {
        const availableUsers = users.filter(user => !selectedUsers.includes(user.id));
        if (availableUsers.length === 0) {
            alert("모든 사용자가 이미 선택되었습니다.");
            return;
        }
        const randomIndex = Math.floor(Math.random() * availableUsers.length);
        const chosenUser = availableUsers[randomIndex];
        setRandomUser(chosenUser);
        setSelectedUsers([...selectedUsers, chosenUser.id]);
    };

    const resetSelection = () => {
        setSelectedUsers([]);
        setRandomUser(null);
    };

    const renderUserRows = () => {
        return users.map(user => (
            <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.name}</td>
            </tr>
        ));
    };

    return (
        <Container className="mt-5">
            <h2>랜덤 인원 뽑기</h2>
            <div className="d-flex justify-content-between align-items-center mb-4">
                <Button variant="primary" onClick={() => setShowModal(true)}>
                    인원 추가
                </Button>
                <Button variant="success" onClick={pickRandomUser}>
                    랜덤 인원 뽑기
                </Button>
                <Button variant="danger" onClick={resetSelection}>
                    초기화
                </Button>
            </div>

            <div style={{ overflowX: "auto" }} className="w-100">
                <Table striped bordered hover variant="dark">
                    <thead>
                        <tr>
                            <th>학번</th>
                            <th>이름</th>
                        </tr>
                    </thead>
                    <tbody>{renderUserRows()}</tbody>
                </Table>
            </div>

            {randomUser && (
                <div className="mt-4">
                    <h4>선택된 인원: {randomUser.name}</h4>
                </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>새로운 인원 추가</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="formId">
                            <Form.Label>학번</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="학번을 입력해주세요"
                                name="id"
                                value={newUser.id}
                                onChange={e => setNewUser({ ...newUser, id: e.target.value })}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>이름</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="이름을 입력해주세요"
                                name="name"
                                value={newUser.name}
                                onChange={e => setNewUser({ ...newUser, name: e.target.value })}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>
                        닫기
                    </Button>
                    <Button variant="primary" onClick={addUser}>
                        추가
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

export default RandomPickerView;