import { faArrowCircleLeft, faArrowCircleRight, faArrowPointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Container } from "react-bootstrap";
import '../css/ApplyEvent.css';

export default function ApplyEvent() {
    return (
        <>
            <Container className="d-flex flex-column justify-content-around align-items-center bg-warning" style={{ minHeight: "70vh" }}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <span className="h4">컴퓨터소프트웨어공학과 학술동아리<br /> <strong>MOS</strong></span>
                    <br />
                    <span className="h6 mb-5">모집기간 : 2024년 3월 4일 ~ 2024년 3월 20일</span>
                    <Button variant="primary" onClick={() => window.location = "/apply"}><FontAwesomeIcon className="me-2" icon={faArrowPointer} />함께하기<FontAwesomeIcon className="ms-2" icon={faArrowPointer} /></Button>
                </div>
                <img className="detail" onClick={()=>window.location="#detail-start"} src={"img/detail-button.png"} width="150px" heigh="40px">

                </img>
            </Container>
            <Container className="mt-1 d-flex flex-column p-0 overflow-hidden" style={{ minHeight: "30vh" }}>
            <div className="w-100">
                    <div className="w-100 bg-white" style={{ minHeight: "25vh" }} data-aos="fade-left" data-aos-once="true" data-aos-duration="1000">

                    </div>
                    <div className="d-flex flex-column align-items-start w-100" style={{ minHeight: "20vh" }} data-aos="fade-right" data-aos-once="true" data-aos-duration="1000">
                        <span className="h4 m-3">
                            <FontAwesomeIcon className="me-1" icon={faArrowCircleRight}/>재미있는 동아리 활동
                        </span>
                        <p className="text-start m-3">
                            아무래도 동아리는 즐거워야죠! 즐거운 동아리 활동을 위한 회식, MT 등 여러 활동이 준비되어 있습니다.
                        </p>
                    </div>
                </div>
            </Container>
            <Container id="detail-start" className="mt-1 d-flex flex-column p-0 overflow-hidden" style={{ minHeight: "30vh" }}>
                <div className="w-100">
                    <div className="w-100 bg-white" style={{ minHeight: "25vh" }} data-aos="fade-left" data-aos-once="true" data-aos-duration="1000">

                    </div>
                    <div className="d-flex flex-column align-items-start w-100" style={{ minHeight: "20vh" }} data-aos="fade-right" data-aos-once="true" data-aos-duration="1000">
                        <span className="h4 m-3">
                            <FontAwesomeIcon className="me-1" icon={faArrowCircleRight}/>언어 학습 세미나
                        </span>
                        <p className="text-start m-3">
                            컴퓨터 프로그래밍의 기초인 프로그래밍 언어! 동아리 부원들이 함께 컴퓨터 언어를 익히기 위한 세미나를 진행합니다.
                        </p>
                    </div>
                </div>
            </Container>
            <Container id="detail-start" className="mt-1 d-flex flex-column p-0 overflow-hidden" style={{ minHeight: "30vh" }}>
                <div className="w-100">
                    <div className="w-100 bg-white" style={{ minHeight: "25vh" }} data-aos="fade-left" data-aos-once="true" data-aos-duration="1000">

                    </div>
                    <div className="d-flex flex-column align-items-start w-100" style={{ minHeight: "20vh" }} data-aos="fade-right" data-aos-once="true" data-aos-duration="1000">
                        <span className="h4 m-3">
                            <FontAwesomeIcon className="me-1" icon={faArrowCircleRight}/>팀 프로젝트 진행
                        </span>
                        <p className="text-start m-3">
                            다양한 포트폴리오 준비를 위한 가장 좋은 방법! 팀 프로젝트를 진행하며 경험을 쌓을 수 있습니다.
                        </p>
                    </div>
                </div>
            </Container>
            <Container style={{minHeight: "5px", maxHeight: "5px"}} className="bg-white mt-3 mb-3"/>
            <Container className="mt-1 d-flex flex-column justify-content-center align-items-center p-0 m-3" style={{minHeight: "30vh"}}>
                <span>우리 동아리의 모토는 <br/><strong className="text-warning">"놀 때는 확실히 놀고 공부할 때는 확실히 공부하자"</strong><br/> 입니다! 재미있는 동아리 활동 같이 하실래요?</span>
                <Button className="" variant="primary"><FontAwesomeIcon className="me-2" icon={faArrowPointer} />함께하기<FontAwesomeIcon className="ms-2" icon={faArrowPointer} /></Button>
            </Container>
        </>
    )
}