import { useEffect, useState } from "react";
import { Container, Table } from "react-bootstrap";
import { getAttendanceList, getSessionList } from "../../firebase";  // Assume these functions fetch the data from your backend.

function UserAttendanceView() {
    const [attendees, setAttendees] = useState([]);
    const [sessions, setSessions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const attendanceData = await getAttendanceList();
            const sessionData = await getSessionList();
            setAttendees(attendanceData);
            setSessions(sessionData);
        };
        
        fetchData();
    }, []);

    const renderHeader = () => {
        const headers = [
            <th key="number">#</th>,
            <th key="name">이름</th>
        ];
        sessions.forEach((session, index) => {
            const datetime = new Date(session);
            const year = datetime.getFullYear();
            let month = '' + (datetime.getMonth() + 1);
            if (month.length === 1) month = "0" + month;
            let date = '' + (datetime.getDate());
            if (date.length === 1) date = "0" + date;
            const dateline = year + "-" + month + "-" + date;
            headers.push(<th key={`session-${index}`}>{dateline}</th>);
        });
        return <tr>{headers}</tr>;
    };

    const renderBody = () => {
        return attendees.map((attendee, index) => (
            <tr key={attendee.memberId}>
                <td>{index + 1}</td>
                <td>{attendee.memberName}</td>
                {sessions.map((_, sessionIndex) => (
                    <td key={sessionIndex}>
                        {attendee.memberStatus[sessionIndex] === "present" ? "출석" : "불참"}
                    </td>
                ))}
            </tr>
        ));
    };

    return (
        <Container className="mt-5">
            <h2>출석 현황</h2>
            <Table striped bordered hover variant="dark" className="mt-3">
                <thead>
                    {renderHeader()}
                </thead>
                <tbody>
                    {renderBody()}
                </tbody>
            </Table>
        </Container>
    );
}

export default UserAttendanceView;
